import React from 'react';
import ComingSoon from '../../components/coming-soon';
import Layout from "../../components/layout"
// import { Helmet } from 'react-helmet'
import SEO from "../../components/seo"
import ProTabs from '../../components/pro-tabs'
import {tabsContent} from "../../lib/pro-tabs-content"

const WhiteLabel = () => {
  const comingSoonDetails = {
    backgroundImage: require('../../images/pro-banner7@2x.jpg'),
    heading: "If you need assistance, we're here to help you through",
    subheading: "Priority Support incidents let you push your query to the top of the queue when it matters most and give you access to our most experienced team members - who can offer value added services like light development support (we'll help you to embed your Javascript). Three incidents per month included."
  }

  return(
    <Layout>
      <SEO 
        title="Priority Support | Billsby Pro | Take your subscription business to the next level" 
        description="Priority Support and other Billsby Pro features let you take your subscription business to the next level." 
        url="https://www.billsby.com/pro/priority-support"
      />

      <ComingSoon 
        backgroundImage={comingSoonDetails.backgroundImage}
        heading={comingSoonDetails.heading}
        subheading={comingSoonDetails.subheading} />
      <ProTabs content={tabsContent} />
    </Layout>
  )
}

export default WhiteLabel;